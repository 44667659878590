import axios from './axios';
import { womBaseURL } from 'constants/stores';
import { womProdBaseURL } from 'constants/services';

export const getChannelPlaylist = (data: WOM.QueryChannelPlaylistRequest) =>
    axios<WOM.QueryChannelPlaylistResponse>(
        {
            url: '/channel/query-playlist',
            baseURL: womBaseURL(),
            data
        },
        false
    );

export const checkChannelPlaylist = (data: WOM.QueryChannelPlaylistRequest) =>
    axios<WOM.QueryChannelPlaylistResponse>(
        {
            url: '/channel/query-playlist',
            baseURL: womProdBaseURL,
            data
        },
        false
    );
