import * as product from './product';
import * as user from './user';
import * as video from './video';
import * as channels from './channels';

export const API = {
    user,
    video,
    product,
    channels
};
