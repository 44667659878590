import styled, { css } from 'styled-components';
import { Sizes } from 'types/styles';
import next_button from 'assets/icons/next_button.svg';
import prev_button from 'assets/icons/prev_button.svg';
import { controlButtonDiameter } from 'pages/Main/constants';

const SwiperStyle = css`
    .swiper-container {
        height: 100%;
        width: 100%;
    }

    .swiper-container-vertical > .swiper-scrollbar {
        display: none;
    }

    .swiper-button-prev,
    .swiper-button-next {
        width: ${controlButtonDiameter};
        height: ${controlButtonDiameter};
    }
    .swiper-button-prev:after {
        content: url(${prev_button});
    }

    .swiper-button-next:after {
        content: url(${next_button});
    }
`;

export const Wrapper = styled.div<Sizes>`
    width: ${({ width }) => width || '100%'};
    height: ${({ height }) => height || '100%'};

    ${SwiperStyle}
`;
