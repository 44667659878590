import { createEffect, createStore } from 'effector';
import { API } from 'services';
import { loadingEffects } from 'stores/loading';

export type GetChannelPlaylistProps = Omit<WOM.QueryChannelPlaylistRequest, 'pageIndex' | 'limit' | 'returnedRecords'>;

const checkChannelPlaylist = createEffect({
    handler: async (values: GetChannelPlaylistProps) => {
        try {
            loadingEffects.updateInitialLoading();
            const data = await API.channels.checkChannelPlaylist({
                ...values,
                pageIndex: 0,
                limit: 99,
                returnQueryCount: true
            });
            loadingEffects.updateInitialLoading();
            data.selector = values.selector;
            return data;
        } catch (err) {
            loadingEffects.updateInitialLoading();
            err.data.selector = values.selector;
            return err.data;
        }
    }
});

const checkPlaylist = createStore<any>({});

checkPlaylist.on(checkChannelPlaylist.doneData, (state, newState) => ({ ...state, ...newState }));

const checkEffects = { checkChannelPlaylist };
const checkStores = { checkPlaylist };

export { checkStores, checkEffects };
