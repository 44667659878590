export const getHeightAndWidthVideo = (Video: Element) => {
    // RT: checking if this code is needed
    // @ts-ignore
    const heightString = Video.style.height;
    // @ts-ignore
    const widthString = Video.style.width;

    const heightNumber = heightString ? Number(heightString.split('px')[0]) : null;
    const widthNumber = widthString ? Number(widthString.split('px')[0]) : null;

    const heightParentBlock = heightNumber || Video.clientHeight;
    const widthParentBlock = widthNumber || Video.clientWidth;

    return [heightParentBlock.toString(), widthParentBlock.toString()];
};
