import {
    infoWrapperHorizontalMargin,
    infoWrapperVerticalMargin
} from 'components/common/videoComponents/VideoPlayer/constants';
import { flexCenter, scrollBarMobileMixin } from 'constants/styles/mixins';
import styled from 'styled-components';

interface Props {
    progressColor?: string;
    textColor?: string;
}

export const ProductTitle = styled.span`
    font-family: inherit;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;

    color: #e8e8e8;

    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 7px;
`;

export const ProductDescription = styled.span`
    font-family: inherit;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;

    color: #e8e8e8;

    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const InfoWrapper = styled.div`
    position: absolute;
    top: ${infoWrapperVerticalMargin};
    left: ${infoWrapperHorizontalMargin};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 2 * ${infoWrapperHorizontalMargin});
    height: calc(100% - 2 * ${infoWrapperVerticalMargin});
    z-index: 11;
`;

export const VideoPlay = styled.video`
    height: ${({ height }) => (height ? height : '100%')};
    background-color: black;
    position: absolute;
    top: 0;
    left: 0;
`;

export const VideoPlayerWrapper = styled.div`
    width: 100%;
    height: 100%;
`;

export const HashtagWrapper = styled.div<Props>`
    display: inline-block;
    padding: 0 12px;
    padding-top: 5px;
    height: 30px;
    background: ${({ progressColor }) => progressColor || '#00ff44'};
    border-radius: 15px;
    margin-right: 12px;
    box-sizing: border-box;
    white-space: nowrap;

    font-family: inherit;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;

    color: ${({ textColor }) => textColor || 'black'};
`;

export const ProductAbsoluteWrapper = styled.div`
    position: absolute;
    bottom: 60px;
    left: 13px;
    width: calc(100% - 26px);
    height: 66px;
    background: rgba(0, 0, 0, 0.58);
    border-radius: 12px;
    padding: 5px 7px 6px;
    z-index: 12;
    box-sizing: border-box;
    overflow: hidden;
`;

export const HashtagsAbsoluteWrapper = styled.div`
    position: absolute;
    left: 0;
    bottom: -13px;
    z-index: 12;
    width: 100%;
`;

export const ProductImageWrapper = styled.div`
    ${flexCenter};
    overflow: hidden;
    width: 51px;
    height: 55px;
    border-radius: 10px;
`;

export const ScrolledWrapper = styled.div`
    padding: 0 30px;
    overflow-y: hidden;
    overflow-x: scroll;
    scroll-behavior: smooth;
    width: 100%;
    height: 60px;
    ${scrollBarMobileMixin}
    box-sizing: border-box;
`;
