import { email, password } from 'constants/services';
import { createEffect, createStore } from 'effector';
import { v4 as uuid4 } from 'uuid';
import { API } from '../services';
//import { initStores } from './init';
import { videoEffects } from './video';

// !!! this logic is not used anywhere
const loadToken = createEffect({
    handler: async () => {
        videoEffects.setLoading(true);

        return await API.user.authenticateUser({
            email,
            // organizationId: initStores.initialStore.getState().organizationPublicId || '',
            password
        });
    }
});

const user = createStore<WOM.UserJwtTokenResponse>({}).on(loadToken.doneData, (_, user) => user);
const sessionId = createStore<string>(uuid4());

export const userEffects = { loadToken };
export const userStores = { user, sessionId };
