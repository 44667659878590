import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { checkEffects } from 'stores/check';
import { initEvents } from 'stores/init';
import { WOMInitParam } from 'types/plugin';
import { App } from './App';

// const pluginElementId = 'wom-channel-viewer-plugin';
// const rootElement = document.getElementById(pluginElementId);

// if (process.env.NODE_ENV === 'production') {
window.womChannelViewer = {
    check: (params: WOMInitParam | WOMInitParam[]) =>
        new Promise((resolve, reject) => {
            if (params instanceof Array) {
                let promises: any = [];

                params.forEach(instance => {
                    const { merchantId, channelId, selector } = instance;
                    const obj = { merchantId, channelId, selector };
                    const res = checkEffects.checkChannelPlaylist(obj);
                    promises.push(res);
                });
                Promise.allSettled(promises).then(results => {
                    let arr: any = [];
                    // @ts-ignore
                    results.forEach((element: any) => {
                        if (element.value && element.value.returnedRecords) {
                            arr.push({ isSuccess: true, message: 'success', selector: element.value.selector });
                        } else {
                            if (element.value && element.value.returnedRecords === 0) {
                                arr.push({
                                    isSuccess: false,
                                    message: 'There were no videos found',
                                    selector: element.value.selector
                                });
                            } else {
                                // @ts-ignore
                                arr.push({
                                    isSuccess: false,
                                    message: element.value.message || 'There was a problem',
                                    selector: element.value.selector
                                });
                            }
                        }
                    });
                    resolve(arr);
                });
            } else {
                const { merchantId, channelId, selector } = params;
                const obj = { merchantId, channelId };

                const res = checkEffects.checkChannelPlaylist(obj);
                res.then(val => {
                    if (val && val.returnedRecords) {
                        resolve([{ isSuccess: true, message: 'success', selector }]);
                    } else {
                        if (val && val.returnedRecords === 0) {
                            reject([{ isSuccess: false, message: 'There were no videos found', selector }]);
                        } else {
                            // @ts-ignore
                            reject([{ isSuccess: false, message: val.message || 'There was a problem', selector }]);
                        }
                    }
                });
            }
        }),
    init: (params: WOMInitParam | WOMInitParam[]) => {
        if (params instanceof Array) {
            params.forEach(instance => {
                const { selector, isDev, ...appParams } = instance;
                const renderElement = document.querySelector<HTMLElement>(selector);

                initEvents.setSelector(selector);
                ReactDOM.render(
                    <StrictMode>
                        <App
                            height={renderElement ? renderElement.style.height : '100%'}
                            isDev={isDev}
                            {...appParams}
                        />
                    </StrictMode>,
                    renderElement
                );
            });
        } else {
            const { selector, isDev, ...appParams } = params;
            const renderElement = document.querySelector<HTMLElement>(selector);

            initEvents.setSelector(selector);
            if (renderElement) {
                ReactDOM.render(
                    <StrictMode>
                        <App
                            height={renderElement ? renderElement.style.height : '100%'}
                            isDev={isDev}
                            {...appParams}
                        />
                    </StrictMode>,
                    renderElement
                );
            }
        }
    }
};
// } else {
//     // Mock data
//     let height = '100%';

//     initEvents.setSelector('#wom-channel-viewer-plugin');
//     const element = document.getElementById('wom-channel-viewer-plugin');
//     if (element) {
//         element.setAttribute('style', 'width: 285px; height: 600px');
//         height = element.style.height || '100%';
//     }

//     ReactDOM.render(
//         <StrictMode>
//             {/*Mock data*/}
//             <App
//                 isDev
//                 channelId="62ffc33c867a018f85c1aaa1"
//                 height={height || (rootElement ? rootElement.style.height : '100%')}
//                 merchantId="ICWT3_60502f360b62caccafa06037"
//                 //organizationPublicId="INCODEWETRUST1_60950f88936aed152f526fc2"
//                 //remoteProductId="6698870276282"
//             />
//         </StrictMode>,
//         rootElement
//     );
// }

let event = new Event('wom-channel-viewer-init', { bubbles: true });
document.body.dispatchEvent(event);
