import { createEffect, createEvent, createStore } from 'effector';
import { API } from 'services';
import { loadingEffects } from 'stores/loading';

export type GetChannelPlaylistProps = Omit<WOM.QueryChannelPlaylistRequest, 'pageIndex' | 'limit' | 'returnedRecords'>;

const getChannelPlaylist = createEffect({
    handler: async (values: GetChannelPlaylistProps) => {
        try {
            loadingEffects.updateInitialLoading();
            const data = await API.channels.getChannelPlaylist({
                ...values,
                pageIndex: 0,
                limit: 99,
                returnQueryCount: true
            });
            loadingEffects.updateInitialLoading();
            let channelId = values.channelId;
            let obj: Record<string, any> = {};
            data.progressColor = values.color;
            data.textColor = values.textColor;
            obj[channelId] = data;
            obj[channelId].items = obj[channelId].items.map((item: any) => {
                item.isMuted = false;
                return item;
            });
            return obj;
        } catch {
            loadingEffects.updateInitialLoading();
            return {};
        }
    }
});

const channelPlaylist = createStore<any>({});

const updateIsMuted = createEvent<any>({});

channelPlaylist
    .on(getChannelPlaylist.doneData, (state, newState) => ({ ...state, ...newState }))
    .on(updateIsMuted, (_, newState) => newState);

const channelsEffects = { getChannelPlaylist, updateIsMuted };
const channelsStores = { channelPlaylist };
const channelEvents = { updateIsMuted };

export { channelsStores, channelsEffects, channelEvents };
