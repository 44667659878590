import axios from './axios';
import { womBaseURL } from 'constants/stores';

export const getProductContent = (data: WOM.QueryProductViewerContentRequest) =>
    axios<WOM.QueryProductViewerContentResponse>(
        {
            url: '/product/remote/query-content',
            baseURL: womBaseURL(),
            data
        },
        false
    );
