import { combine, createEffect, createEvent, createStore } from 'effector';
import { API } from 'services';

const setLoading = createEvent<boolean>();
const loading = createStore<boolean>(false).on(setLoading, (_, state) => state);

const loadVideosByUserId = createEffect({
    handler: async (byUserId: string) => {
        try {
            setLoading(true);
            const result = await API.video.getVideo({ byUserId: byUserId, pageIndex: 0, limit: 100 });
            setLoading(false);
            return result.items as WOM.ContentItemResponse[];
        } catch (e) {
            return {} as WOM.ContentItemResponse[];
        }
    }
});

const video = createStore<WOM.ContentItemResponse[]>([]).on(loadVideosByUserId.doneData, (_, items) => items);

const videoWithLoading = combine(video, loading);

const updateIsMuted = createEvent();
const isMuted = createStore(false).on(updateIsMuted, state => !state);

export const videoEffects = { loadVideosByUserId, setLoading };
export const videoEvents = { updateIsMuted };
export const videoStores = { video, videoWithLoading, isMuted };
