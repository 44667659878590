import axios from './axios';
import { womBaseURL } from 'constants/stores';

export const authenticateUser = (data: WOM.OrganizationUserAuthChallengeRequest) =>
    axios<WOM.UserJwtTokenResponse>(
        {
            url: 'organization/user/authenticate',
            baseURL: womBaseURL(),
            data
        },
        false
    );
