import { userIdWithVideo } from 'constants/services';
import { createEffect, createEvent, createStore } from 'effector';
import { videoEffects } from 'stores/video';
import { InitialStores } from 'types/store';
import { userEffects } from './user';

// !!! this logic is not used anywhere
const initEvent = createEvent<InitialStores>();

const initApp = createEffect({
    handler: async (params: InitialStores) => {
        initEvent(params);
        await userEffects.loadToken();
        await videoEffects.loadVideosByUserId(userIdWithVideo);
    }
});

const initialStore = createStore<InitialStores>({}).on(initEvent, (_, payload) => payload);

const setSelector = createEvent<string>();
const selector = createStore<any>([]).on(setSelector, (state, newState) => [...state, newState]);

export const initEvents = { initEvent, setSelector };
export const initEffects = { initApp };
export const initStores = { initialStore, selector };
