import { VideoPlayer } from 'components/common/videoComponents/VideoPlayer';
import { MobileLoader } from 'components/dynamic/Loader';
import { useStore } from 'effector-react';
import { TChannelIdProps, withAdaptiveComponent } from 'hocs/withAdaptiveComponent';
import React, { FC, useState } from 'react';
import { channelsStores } from 'stores/channels';
import { initStores } from 'stores/init';
import SwiperCore, { Navigation, Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import SwiperClass from 'swiper/types/swiper-class';
import { getHeightAndWidthVideo } from 'utils/video';
import { Wrapper } from './styles';

// install Swiper modules
SwiperCore.use([Virtual, Navigation]);

export const MainComponent: FC<TChannelIdProps> = ({ width, height, channelId, merchantId }) => {
    const store = useStore(channelsStores.channelPlaylist);
    const items = store[channelId]?.items;

    const videosCount = items?.length || 0;
    const [activeVideoIndex, setActiveVideoIndex] = useState(0);
    const [isVideoHorizontal, setHorizontal] = useState(false);

    const handleChangeSlide = ({ activeIndex }: SwiperClass) => {
        setActiveVideoIndex(activeIndex);
        let allVideos: any[] = [];
        initStores.selector.getState().forEach((item: any) => {
            let videosArray = document.querySelectorAll(`${item} video`);
            videosArray.forEach(item => allVideos.push(item));
        });
        const currentVideo = allVideos[activeIndex];
        const [heightNum, widthNum] = getHeightAndWidthVideo(currentVideo);
        setHorizontal(Number(heightNum) < Number(widthNum));
    };

    return (
        <Wrapper height={height + 'px'} width={width + 'px'}>
            <Swiper
                direction="horizontal"
                navigation={videosCount > 1}
                simulateTouch={false}
                onSlideChangeTransitionEnd={handleChangeSlide}
            >
                {items?.map((video: any, i: number) => (
                    <SwiperSlide key={i.toString()}>
                        {activeVideoIndex === i ? (
                            <VideoPlayer
                                channelId={channelId}
                                index={i}
                                isActive={activeVideoIndex === i}
                                isHorizontal={isVideoHorizontal}
                                merchantId={merchantId}
                                video={video}
                            />
                        ) : (
                            <MobileLoader />
                        )}
                    </SwiperSlide>
                ))}
            </Swiper>
        </Wrapper>
    );
};

export const Main = withAdaptiveComponent<TChannelIdProps>(MainComponent);
