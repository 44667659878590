import defaultProduct from 'assets/defaults/default_product.png';
import scrollButton from 'assets/img/scrollButton.svg';
import { CustomImage } from 'components/common/imgComponents/CustomImg/styles';
import { VideoFooter } from 'components/common/videoComponents/VIdeoFooter';
import { VideoHeader } from 'components/common/videoComponents/VideoHeader';
import { MobileLoader } from 'components/dynamic/Loader';
import { Notifications } from 'components/Notifications';
import { AbsoluteWrapper } from 'components/wrappers/AbsoluteWrapper';
import { Column, Row } from 'components/wrappers/FlexWrapper';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { RelativeWrapper } from 'components/wrappers/RelativeWrapper';
import { useStore } from 'effector-react';
import Hls from 'hls.js';
import React, { FC, useEffect, useRef, useState } from 'react';
import { channelEvents, channelsStores } from 'stores/channels';
import { userStores } from 'stores/user';
import { sizesStores } from 'stores/sizes';
import {
    HashtagsAbsoluteWrapper,
    HashtagWrapper,
    InfoWrapper,
    ProductAbsoluteWrapper,
    ProductDescription,
    ProductImageWrapper,
    ProductTitle,
    ScrolledWrapper,
    VideoPlay,
    VideoPlayerWrapper
} from './style';

interface VideoPlayerProps {
    //video: WOM.ContentResponse;
    video: WOM.ChannelPlaylistResponse;
    isActive: boolean;
    customHeight?: string;
    customWidth?: string;
    isHorizontal: boolean;
    channelId: string;
    index: number;
    merchantId?: string;
}

const ScrolledBlock: FC = ({ children }) => {
    const scrolledBlockRef = useRef<HTMLDivElement>(null);
    const onScrollLeft = () => {
        if (!scrolledBlockRef.current) return;

        const scrolledBlock = scrolledBlockRef.current;

        scrolledBlock.scrollLeft -= 100;
    };

    const onScrollRight = () => {
        if (!scrolledBlockRef.current) return;

        const scrolledBlock = scrolledBlockRef.current;

        scrolledBlock.scrollLeft += 100;
    };

    return (
        <ScrolledWrapper ref={scrolledBlockRef}>
            <AbsoluteWrapper left="5px" top="5px" onClick={onScrollLeft}>
                <CustomImage height="20px" src={scrollButton} width="20px" />
            </AbsoluteWrapper>
            <AbsoluteWrapper right="5px" top="5px" onClick={onScrollRight}>
                <CustomImage height="20px" rotate={180} src={scrollButton} width="20px" />
            </AbsoluteWrapper>
            <Row noWrap>{children}</Row>
        </ScrolledWrapper>
    );
};

export const VideoPlayer: FC<VideoPlayerProps> = ({ video, isActive, isHorizontal, channelId, index, merchantId }) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    const height = useStore(sizesStores.sizesHeight);
    const sessionId = useStore(userStores.sessionId);

    const channelStore = useStore(channelsStores.channelPlaylist);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [duration, setDuration] = useState(0);

    const setDurationVideo = (videoPlayer: HTMLVideoElement) => {
        videoPlayer.addEventListener('loadedmetadata', () => {
            setDuration(videoPlayer.duration);
        });
    };

    const handleMute = (index: number): void => {
        let obj = { ...channelStore };
        obj[channelId].items[index].isMuted = !obj[channelId].items[index].isMuted;
        channelEvents.updateIsMuted(obj);
    };
    useEffect(() => {
        if (!videoRef.current || !video?.streamingUrl) return;
        const videoSrc = `${video.streamingUrl}?t=1&c=${video.contentId}&ch=${channelId}&m=${merchantId}&s=${sessionId}`;
        // t - type. 1 for Channel Viewer
        const videoPlayer = videoRef.current;
        if (Hls.isSupported()) {
            const hls = new Hls();
            hls.loadSource(videoSrc);
            hls.attachMedia(videoPlayer);
            hls.on(Hls.Events.MANIFEST_PARSED, () => {
                setIsLoading(false);
                videoPlayer.play();
                setDurationVideo(videoPlayer);
            });
        } else if (videoPlayer.canPlayType('application/vnd.apple.mpegurl')) {
            videoPlayer.src = videoSrc;
            videoPlayer.addEventListener('loadedmetadata', () => {
                videoPlayer.play();
                setDuration(videoPlayer.duration);
                // effects.setLoadedVideo(true);
            });
        }
    }, [video, merchantId, sessionId, channelId]);
    // TODO: api
    return (
        <VideoPlayerWrapper>
            <RelativeWrapper height="100%" width="auto">
                <Notifications />
                {!video ? (
                    <MobileLoader />
                ) : (
                    <>
                        <InfoWrapper onClick={() => handleMute(index)}>
                            <VideoHeader
                                duration={duration}
                                isLoading={isLoading}
                                isMuted={isActive && channelStore[channelId].items[index].isMuted}
                                progressColor={channelStore[channelId].progressColor}
                                src={''}
                                username={'some'}
                            />
                            <VideoFooter />
                        </InfoWrapper>
                        <VideoPlay
                            ref={videoRef}
                            loop
                            playsInline
                            height={height}
                            muted={!(isActive && channelStore[channelId].items[index].isMuted)}
                            style={{ width: isHorizontal ? '100%' : 'auto' }}
                        />
                        {video.product?.name || video.product?.brand || video.thumbnailUrl ? (
                            <ProductAbsoluteWrapper>
                                <Row noWrap>
                                    <ProductImageWrapper>
                                        <CustomImage
                                            height="auto"
                                            src={video.thumbnailUrl || defaultProduct}
                                            width="51px"
                                        ></CustomImage>
                                    </ProductImageWrapper>
                                    <MarginWrapper marginLeft="9px" marginTop="7px">
                                        <Column>
                                            <ProductTitle>
                                                {/* @ts-ignore */}
                                                {video.product?.name.length >= 15
                                                    ? `${video.product?.name?.slice(0, 10)}...`
                                                    : video.product?.name}
                                            </ProductTitle>
                                            <ProductDescription>{video.product?.brand}</ProductDescription>
                                        </Column>
                                    </MarginWrapper>
                                </Row>
                            </ProductAbsoluteWrapper>
                        ) : null}
                        <HashtagsAbsoluteWrapper>
                            <ScrolledBlock>
                                {video.tags?.map(it => (
                                    <HashtagWrapper
                                        key={it}
                                        progressColor={channelStore[channelId].progressColor}
                                        textColor={channelStore[channelId].textColor}
                                    >{`#${it}`}</HashtagWrapper>
                                ))}
                            </ScrolledBlock>
                        </HashtagsAbsoluteWrapper>
                    </>
                )}
            </RelativeWrapper>
        </VideoPlayerWrapper>
    );
};
