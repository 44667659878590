import { Main } from 'pages/Main';
import React, { useEffect } from 'react';
import { InitialParams } from 'types/plugin';
import { womDevBaseURL, womProdBaseURL } from 'constants/services';
import { globalEvents } from 'stores/global';
import { Wrapper } from 'components/wrappers/ApplicationWrapper/styles';
import { channelsEffects, channelsStores } from 'stores/channels';
import { sizesEvents } from 'stores/sizes';
import { useStore } from 'effector-react';

interface Props extends InitialParams {}

export const App = ({ isDev, height, ...queryProps }: Props) => {
    const store = useStore(channelsStores.channelPlaylist);
    sizesEvents.setHeightString(height ? height : '100%');
    const { channelId, merchantId } = queryProps;

    useEffect(() => {
        globalEvents.setWOMBaseURL(isDev ? womDevBaseURL : womProdBaseURL);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        //productEffects.getProductContent(queryProps);
        channelsEffects.getChannelPlaylist(queryProps);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // * mock data
    if (!store[channelId]?.items.length) return <div style={{ color: 'red' }} />;

    return (
        <Wrapper>
            <Main channelId={channelId} merchantId={merchantId} />
        </Wrapper>
    );
};
