import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { REACT_APP_API_URL } from 'constants/services';
import { userStores } from 'stores/user';

const womAxiosInstance = axios.create();

womAxiosInstance.defaults.baseURL = REACT_APP_API_URL;
womAxiosInstance.defaults.method = 'POST';
womAxiosInstance.interceptors.response.use(
    config => config.data,
    config => Promise.reject(config.response)
);

// eslint-disable-next-line import/no-anonymous-default-export
export default <T = void>(config: AxiosRequestConfig, withToken = true) => {
    const request: AxiosPromise<T> = womAxiosInstance({
        ...config,
        headers: withToken
            ? {
                  Authorization: `Bearer ${userStores.user.getState().token}`
              }
            : {}
    });
    return (request as any) as Promise<T>;
};
