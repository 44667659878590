import React, { Component, FC } from 'react';
import { heightToWidthVideoRatio } from 'constants/video';
import { getHeightAndWidthVideo } from 'utils/video';
import { initStores } from 'stores/init';

const getPluginElement = () =>
    document.querySelector(`${initStores.selector.getState()}`) || document.createElement('div');

export type TChannelIdProps = {
    channelId: string;
    width?: number;
    height?: number;
    merchantId?: string;
};

export function withAdaptiveComponent<T extends TChannelIdProps>(WrappedComponent: FC<TChannelIdProps>) {
    return class extends Component<T> {
        state = {
            width: getPluginElement().clientHeight / heightToWidthVideoRatio,
            height: getPluginElement().clientHeight
        };

        updateSize = () => {
            const [height, width] = getHeightAndWidthVideo(getPluginElement());
            this.setState({
                width,
                height
            });
        };

        componentDidMount() {
            //for bug with integration to shopify
            setTimeout(() => {
                this.updateSize();
            }, 0);

            window.addEventListener('resize', this.updateSize);
        }

        componentWillUnmount() {
            window.removeEventListener('resize', this.updateSize);
        }

        render() {
            return <WrappedComponent {...(this.props as T)} {...this.state} />;
        }
    };
}
